


<div class="loader-bg">
  <div class="loader-track">
    <div class="loader-fill"></div>
  </div>
</div>
<!-- [ Pre-loader ] End -->

<div class="auth-main v2">
  <div class="bg-overlay bg-dark"></div>
  <div class="auth-wrapper">
    <div class="auth-sidecontent">
      <div class="auth-sidefooter">
        <img src="../assets/images/logo-dark.svg" class="img-brand img-fluid" alt="images" />
        <hr class="mb-3 mt-4" />
        <div class="row">
          <div class="col my-1">
            <p class="m-0">Made with ♥ by Team <a href="https://themeforest.net/user/phoenixcoded" target="_blank">
                Phoenixcoded</a></p>
          </div>
          <div class="col-auto my-1">
            <ul class="list-inline footer-link mb-0">
              <li class="list-inline-item"><a routerLink="/">Home</a></li>
              <li class="list-inline-item"><a href="https://pcoded.gitbook.io/light-able/"
                  target="_blank">Documentation</a></li>
              <li class="list-inline-item"><a href="https://phoenixcoded.support-hub.io/" target="_blank">Support</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="auth-form">
      <div class="card my-5 mx-3">
        <div class="card-body">
          <form     [formGroup]="loginForm"
          (ngSubmit)="login()">

         
          <h4 class="f-w-500 mb-1">{{ "login" | translate }}</h4>
          <p class="mb-3">Don't have an Account? <a routerLink="/register" class="link-primary ms-1">Create
              Account</a></p>
          <div class="mb-3">
            <input type="text" class="form-control" id="floatingInput" 
            formControlName="username"
            [attr.placeholder]="'USERNAME_PLACEHOLDER' | translate"
            />
          </div>
          <div
          class="text-danger"
          *ngIf="
            loginForm.get('username')?.invalid &&
            (loginForm.get('username')?.dirty ||
              loginForm.get('username')?.touched)
          "
        >
          <div
            class="ms-error"
            *ngIf="loginForm.get('username')?.errors?.['required'] && loginForm.get('username')?.touched"
          >
            إسم المستخدم مطلوب.
          </div>
        </div>
          <div class="mb-3">
            <input type="password" class="form-control" id="floatingInput1"
            formControlName="password"
            [attr.placeholder]="'PASSWORD_PLACEHOLDER' | translate"
            />
          </div>
          <div
          class="text-danger"
          *ngIf="
            loginForm.get('password')?.invalid &&
            (loginForm.get('password')?.dirty ||
              loginForm.get('password')?.touched)
          "
        >
          <!-- <div *ngIf="loginForm.get('password')?.errors?.['required']">Password is required.</div> -->

          <div
            class="ms-error"
            *ngIf="loginForm.get('password')?.errors?.['required'] && loginForm.get('password')?.touched"
          >
            كلمة المرور مطلوبة.
          </div>
          <div
            class="ms-error"
            *ngIf="loginForm.get('password')?.errors?.['minlength'] && loginForm.get('password')?.touched"
          >
            يجب أن تكون كلمة المرور على الأقل
            {{ loginForm.get('password')?.errors?.['minlength'].requiredLength }}
            أحرف.
          </div>
          <div
            class="ms-error"
            *ngIf="loginForm.get('password')?.errors?.['maxlength'] && loginForm.get('password')?.touched"
          >
            يجب ألا تزيد كلمة المرور عن
            {{ loginForm.get('password')?.errors?.['maxlength'].requiredLength }}
            أحرف.
          </div>

          <!-- <div *ngIf="loginForm.get('password')?.errors?.['minlength']">Password must be at least 6 characters long.</div> -->
        </div>
          <div class="d-flex mt-1 justify-content-between align-items-center">
            <div class="form-check">
              <input class="form-check-input input-primary" type="checkbox" id="customCheckc1" checked="" />
              <label class="form-check-label text-muted" for="customCheckc1">Remember me?</label>
            </div>
            <a href="#">
              <h6 class="text-secondary f-w-400 mb-0">Forgot Password?</h6>
            </a>
          </div>
          <div class="d-grid mt-4">
            <button  type="submit" class="btn btn-primary">Login</button>
          </div>

          <div class="text-danger">
              {{errorMessage}}
          </div>
          <!-- <div class="saprator my-3">
            <span>Or continue with</span>
          </div>
          <div class="text-center">
            <ul class="list-inline mx-auto mt-3 mb-0">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/" class="avtar avtar-s rounded-circle bg-facebook" target="_blank">
                  <i class="fab fa-facebook-f text-white"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://twitter.com/" class="avtar avtar-s rounded-circle bg-twitter" target="_blank">
                  <i class="fab fa-twitter text-white"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://myaccount.google.com/" class="avtar avtar-s rounded-circle bg-googleplus"
                  target="_blank">
                  <i class="fab fa-google text-white"></i>
                </a>
              </li>
            </ul>
          </div> -->
        </form>
        </div>
      </div>
    </div>
  </div>
</div>
