import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());
  private userSubject = new BehaviorSubject<any>(this.getUser());

  constructor(private http: HttpClient, private router: Router) {
    this.initCorporateData();
  }

  private hasToken(): boolean {
    return !!localStorage.getItem('ecotoken');
    return !!localStorage.getItem('tokenEliterz');
  }

  private initCorporateData(): void {
    const user = this.getUser();
    if (user) {
      // this.fetchAndStoreCorporateData(user.id);
    }
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user') || 'null');
  }

  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  get user$(): Observable<any> {
    return this.userSubject.asObservable();
  }

  login(data: any, params?: any): Observable<any> {
    let queryParams = new HttpParams();
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          data[key].forEach((value: string, index: number) => {
            formData.append(`${key}[${index}]`, value);
          });
        } else if (data[key] instanceof File) {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    if (params) {
      Object.keys(params).forEach((key) => {
        queryParams = queryParams.append(key, params[key]);
      });
    }
    return this.http.post(`${environment.url}erp/Login/Login`, data, {
      params: queryParams,
    });
  }

  handleLoginResponse(response: any): void {
    // localStorage.setItem('user', JSON.stringify(response.data.user));
    localStorage.setItem('ecotoken', response.responseData.token);
    localStorage.setItem(
      'userRules',
      JSON.stringify(response.responseData.rules)
    );
    // this.userSubject.next(response.data.user);
    this.loggedIn.next(true);
    // this.fetchAndStoreCorporateData(response.data.user.id);
  }

  logOut(){
        localStorage.removeItem('ecotoken');
        localStorage.removeItem('userRules');
        this.loggedIn.next(false);
  }
  getUserRules(key: string = 'userRules'): any[] | null {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }
  // fetchAndStoreCorporateData(userId: number): void {
  //   this.getCorporate(userId).subscribe({
  //     next: (response) => {
  //       if (response.status === 200) {
  //         this.corporateData.next(response.data);
  //         localStorage.setItem('corporateData', JSON.stringify(response.data));
  //       } else {
  //         console.error('Failed to fetch corporate data', response.message);
  //       }
  //     },
  //     error: (error) => {
  //       console.error('Error fetching corporate data', error);
  //     },
  //   });
  // }

  // reinitializeCorporateData(): void {
  //   const user = this.getUser();
  //   if (user) {
  //     this.fetchAndStoreCorporateData(user.id);
  //   }
  // }

  logout(): void {
    // localStorage.removeItem('user');
    localStorage.removeItem('ecotoken');
    this.loggedIn.next(false);
    // this.corporateData.next(null);
    // this.userSubject.next(null);
    // localStorage.removeItem('corporateData');
  }

  // register(params: any): Observable<any> {
  //   return this.http.post(`${environment.authUrl}auth/register`, params);
  // }

  // getCorporate(userId: number): Observable<any> {
  //   return this.http.get(
  //     `${environment.jobsUrl}get-corporate-by-userId?user_id=${userId}`
  //   );
  // }

  getUserAndToken(): Observable<{ token: string | null }> {
    // const user = JSON.parse(localStorage.getItem('user') || 'null');
    const token = localStorage.getItem('tokenEliterz');
    if (token) {
      // if (user && token) {
      // return of({ user, token });
      return of({ token });
    } else {
      return of({ token: null });
    }
  }
}
